import React, { useRef, useState, useEffect } from 'react';
import { Typography, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import useStyles from './styles';

type multilineTextWithToolTipProperties = {
  text: string | undefined;
  customDataTestId?: string;
  className?: string;
  numberOfLines?: 1 | 2 | 3;
};

const LightTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[10],
    maxWidth: 'none',
  },
  arrow: {
    color: theme.palette.common.white,
  },
}));

const MultilineTextWithToolTip = (props: multilineTextWithToolTipProperties) => {
  const { classes } = useStyles();
  const textElementRef = useRef<HTMLElement>(null);
  const { text, customDataTestId, className, numberOfLines = 3 } = props;
  const [hoverStatus, setHover] = useState(true);
  useEffect(() => {
    const defaultLineHeight = 16;

    if (
      textElementRef.current &&
      textElementRef.current.clientHeight > defaultLineHeight * (numberOfLines - 1)
    ) {
      setHover(true);
    } else setHover(false);
  });
  return (
    <LightTooltip
      ref={textElementRef}
      title={text || ''}
      arrow
      placement="top-start"
      disableHoverListener={!hoverStatus}
    >
      <Typography
        data-testid={customDataTestId || 'multiline-tooltip'}
        component="p"
        className={`${classes.text} ${classes[('line' + numberOfLines) as 'line1' | 'line2' | 'line3']}  ${
          className || ''
        }`}
      >
        {text ? text : ''}
      </Typography>
    </LightTooltip>
  );
};

export default MultilineTextWithToolTip;

import { createStore, combineReducers, applyMiddleware, Action, AnyAction } from 'redux';
import thunkMiddleware, { ThunkMiddleware, ThunkAction, ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import loginReducer from './login/reducers';
import customerOrganizationReducer from './customer-organization/reducers';
import dealerCustomerReducer from './dealer-customer/reducers';
import snackbarReducer from './snackbar/reducers';
import recommendationReducer from './recommendations/reducers';
import invitationsReducer from './invitations/reducers';
import userManagementReducer from './user-management/reducers';
import addCustomerReducer from './add-customer/reducers';
import userIdentityReducer from './user-identity/reducers';
import userIdentityManagementReducer from './user-identity-management/reducers';
import unsavedChangesNotificationReducer from './unsaved-changes-notification/reducers';
import mergeOrganizationReducer from './merge-organization/reducers';
import filtersReducer from './filters/reducers';
import countriesStatesReducer from './countries-states/reducers';
import associateCustomersReducer from './associate-customers/reducers';
import customerDetailsByIDReducer from './customer-details-by-id/reducers';
import customerRecommendationsReducer from './customer-recommendations/reducers';
import globalPreferenceReducer from './global-preferences/reducers';
import metadataReducer from './metadata/reducers';
import accessRequestReducer from './access-request/reducers';
import accessManagementReducer from './access-management/reducers';
import { useDispatch } from 'react-redux';
import assetManagementReducer from './asset-management/reducers';
import assetRecommendationsReducer from './asset-recommendations/reducers';
import assetOwnershipReducer from './asset-ownership/reducers';
import ownershipRequestsReducer from './ownership-requests/reducers';
import dealerDetailsReducer from './dealerDetails/reducers';
const rootReducer = combineReducers({
  customer: combineReducers({
    customerDetailsByID: customerDetailsByIDReducer,
    associateCustomers: associateCustomersReducer,
    mergeOrganization: mergeOrganizationReducer,
    customerOrganization: customerOrganizationReducer,
    dealerCustomer: dealerCustomerReducer,
    addCustomer: addCustomerReducer,
    filters: filtersReducer,
    customerRecommendations: customerRecommendationsReducer,
  }),
  login: loginReducer,
  recommendation: recommendationReducer,
  globalPreferences: globalPreferenceReducer,

  shared: combineReducers({
    countriesAndStates: countriesStatesReducer,
    snackbar: snackbarReducer,
    unsavedChangesNotification: unsavedChangesNotificationReducer,
    userIdentity: userIdentityReducer,
    userIdentityManagement: userIdentityManagementReducer,
    userManagement: userManagementReducer,
    metadata: metadataReducer,
    dealerDetails: dealerDetailsReducer,
  }),
  invitation: invitationsReducer,
  accessRequest: accessRequestReducer,
  accessManagement: accessManagementReducer,
  assetManagement: assetManagementReducer,
  assetRecommendations: assetRecommendationsReducer,
  ownershipRequests: ownershipRequestsReducer,
  assetOwnership: assetOwnershipReducer,
});
export type AppState = ReturnType<typeof rootReducer>;
export type AppThunkResult<R> = ThunkAction<R, AppState, undefined, Action>;
export type AppThunkDispatch = ThunkDispatch<AppState, undefined, Action>;
export type DispatchAction<T extends AnyAction = Action> = ThunkDispatch<AppState, undefined, T>;

export const useDispatchTs: () => DispatchAction = useDispatch;

export default function configureStore() {
  const middlewares = [thunkMiddleware as ThunkMiddleware<AppState>];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(rootReducer, composeWithDevTools(middleWareEnhancer));

  return store;
}

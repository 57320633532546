import React, { Dispatch, SetStateAction, useState } from 'react';
import clsx from 'clsx';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Drawer, List, Box, Typography, useTheme, Grid, Button, Divider } from '@mui/material';
import MenuItem from './components/menu-item';
import useStyles from './styles';
import { NavObject, NavSection } from '../../globals/nav-list';
import ScrollModal from './components/scroll-modal';
import LegalText from '../../assets/text/legal';
import PrivacyText from '../../assets/text/privacy';
import { unsavedChangesNotificationActionNames } from '../../store/unsaved-changes-notification/types';

import { AppState, useDispatchTs } from '../../store';
import { getSortedNavList } from '../../utils/nav.util';
import CAT_COLOR from '../../globals/color-properties';

type SideNavProperties = {
  children: React.ReactNode;
  navList: NavSection;
  open?: boolean;
  clickedUri: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setClickedUri: Dispatch<SetStateAction<string>>;
};

const SideNav = ({
  children,
  navList,
  open = false,
  clickedUri,
  setClickedUri,
  setOpen,
}: SideNavProperties) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatchTs();
  const page = useParams();

  const currentPageStatusIsDirty = useSelector(
    (state: AppState) => state.shared.unsavedChangesNotification.currentPageStatus,
  );
  const [isHover, setIsHover] = useState<boolean>(false);

  const active = (key: string[]) => {
    const pageFound = key.find((keypage) => {
      return (
        keypage.split('/')[1] === page['*'] || keypage.split('/')[1] === page['*']?.split('/')[0]
      );
    });
    if (pageFound) {
      return true;
    }
    return false;
  };

  const updateClickedUri = (
    event: React.MouseEvent<HTMLAnchorElement>,
    url: string,
    navItem: NavObject,
  ) => {
    if (currentPageStatusIsDirty) {
      dispatch({
        type: unsavedChangesNotificationActionNames.SET_HANDLE_CONTINUE,
        payload: () => {
          navigate(url || '/my-customers');
          dispatch({
            type: unsavedChangesNotificationActionNames.SET_CURRENT_FORM_IS_DIRTY,
            payload: false,
          });
        },
      });
      event.preventDefault();
    } else if (navItem?.subNavItems && navItem?.subNavItems.length > 0 && setOpen) {
      if (open) {
        setClickedUri(url);
      } else {
        event.preventDefault();
      }
      setOpen(true);
    } else {
      setClickedUri(url);
    }
  };

  const toggleHover = () => {
    isHover ? setIsHover(false) : setIsHover(true);
  };

  return (
    <Box display="flex" width="100%">
      <Drawer
        data-testid="side-bar-drawer"
        classes={{
          paper: clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
      >
        <Box display="flex" flexDirection="column" height="100vh" justifyContent="space-between">
          <Box mt={theme.spacing(0.06)}>
            <List>
              {getSortedNavList(navList).map(([key, navItem]) => (
                <div key={key}>
                  <MenuItem
                    key={navItem.uri}
                    active={active(navItem.relatedPages)}
                    navItem={navItem}
                    uri={navItem.uri || ''}
                    open={open}
                    clickedUrl={clickedUri}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
                      updateClickedUri(event, url, navItem);
                    }}
                  />
                </div>
              ))}
            </List>
          </Box>
          {open && (
            <Box display="flex" justifyContent="center">
              <Box>
                <Divider className={classes.divider} />
                <Grid
                  container
                  data-testid="legal-info"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid>
                    <ScrollModal title="Privacy" bodyText={PrivacyText} mainMenuStyle={true} />
                  </Grid>
                  <Grid style={{ fontWeight: 600, color: CAT_COLOR.BLACK_OLIVE }}>-</Grid>
                  <Grid>
                    <Button
                      id="ot-sdk-btn"
                      className="ot-sdk-show-settings"
                      onMouseEnter={toggleHover}
                      onMouseLeave={toggleHover}
                      style={{
                        border: 'none',
                        fontFamily: 'NotoSans, Roboto, Helvetica, Arial, sans-serif',
                        fontSize: '14px',
                        fontWeight: 600,
                        transition:
                          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        color: CAT_COLOR.BLACK_OLIVE,
                        backgroundColor: isHover ? 'rgba(67, 72, 92, 0.04)' : 'transparent',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        padding: theme.spacing(0.5, 1),
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Cookie Settings
                    </Button>
                  </Grid>
                  <Grid style={{ fontWeight: 600, color: CAT_COLOR.BLACK_OLIVE }}>-</Grid>
                  <Grid>
                    <ScrollModal title="Legal Terms" bodyText={LegalText} mainMenuStyle={true} />
                  </Grid>
                </Grid>
                <Box data-testid="rights-info" className={classes.rightsInfo}>
                  <Typography>
                    <span>&copy;</span> {new Date().getFullYear()} Caterpillar. All Rights Reserved.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </Box>
  );
};

export default SideNav;

export enum EnvironmentName {
  local = 'local',
  development = 'dev',
  integration = 'int',
  production = 'prod',
  fake = 'fake',
}

export const DevEnvironments = [EnvironmentName.local, EnvironmentName.development];

export interface OnboardingApp {
  [key: string]: Array<string>;
}

export type EnvironmentVariables = {
  antiForgeryTtl: number;
  appDynamicsKey: string;
  assetManagementFlag: boolean;
  assetManagementFilters: boolean;
  assetsV2: string;
  subscriptionsV3: string;
  authURI: string;
  blocksComponents: boolean;
  countriesApiFlag: boolean;
  countriesV1: string;
  customerAssetWorkflowV1: string;
  customerMasterURI: string;
  currentEnvironment: string;
  // exclude this field or provide undefined as value if no deployment is planned
  // can also provide past date if deployment is already done
  // format should be MM-DD-YYYY HH:MM
  deploymentDateTime?: string;
  deploymentDuration: number;
  eCommerceV1: string;
  entitlementRoleManagementV1: string;
  entitlementsV1: string;
  hoursBeforeMaintanenceBannerDisplay: number;
  leniency: number;
  logoutURI: string;
  multipleDCNRecommendation: boolean;
  proofCodeTtl: number;
  redirectURI: string;
  refreshSession: boolean;
  testAdminAccessRequestAndManageusersFlag: boolean;
  standardTest: boolean;
  applicationUserManagementFM3Flag: boolean;
  serialNumberValidationFlag: boolean;

  tokenURI: string;
  universalCustomers: string;
  userIdentityManagementV1: string;
  userIdentityV1: string;
  userManagementV1: string;
  userOnboardingV1: string;
  dealerDetailsV1: string;
  manufacturerMetadataV1: string;
  assetMetadataV1: string;
  instanceManagementV2: string;
  userOnboardingApps: OnboardingApp;
  testAdminUserOnboardingApps: OnboardingApp;
  applicationUserManagementFM3Apps: OnboardingApp;

  userPreferencesV2: string;
  announcementStartDate: string;
  announcementEndDate: string;
  announcementContent: string;
  cuobTestFlag: boolean;
  ownershipRequestsFlag: boolean;
  assetRecommendationsFlag: boolean;
  adminsFlag: boolean;
  accessRequestsDateFilterFlag: boolean;
  resentInvitationDetailsFlag: boolean;
  notificationsFlag: boolean;
  autoApproveFlag: boolean;
  assetsDCDCNFlag: boolean;
  restrictedAccountsFlag: boolean;
  customersConsolidateFlag: boolean;
  AMTChangesOwnershipFlag: boolean;
  catFiAssociationFlag: boolean;
};

export const fakeEnvironmentVariables: EnvironmentVariables = {
  antiForgeryTtl: 3,
  appDynamicsKey: 'AD-AAB-ACY-UWW',
  assetManagementFlag: true,
  assetManagementFilters: true,
  assetsV2: 'foo',
  subscriptionsV3: 'foo',
  authURI: 'https://login.uri',
  customerAssetWorkflowV1: 'foo',
  countriesApiFlag: false,
  countriesV1: 'foo',
  customerMasterURI: 'foo',
  currentEnvironment: 'fake',
  deploymentDateTime: undefined, // MM-DD-YYYY HH:MM format
  deploymentDuration: 2,
  eCommerceV1: 'foo',
  entitlementRoleManagementV1: 'foo',
  entitlementsV1: 'foo',
  hoursBeforeMaintanenceBannerDisplay: 24,
  leniency: 2,
  logoutURI: 'https://loginmcqa.rd.cat.com/CwsLogin/cws/logout.htm?appid=login',
  multipleDCNRecommendation: false,
  proofCodeTtl: 5,
  redirectURI: 'https://redirect.uri',
  refreshSession: true,
  testAdminAccessRequestAndManageusersFlag: false,
  standardTest: false,
  applicationUserManagementFM3Flag: false,
  tokenURI: 'https://token.uri',
  universalCustomers: 'foo',
  userIdentityManagementV1: 'foo',
  userIdentityV1: 'foo',
  userManagementV1: 'foo',
  userOnboardingV1: 'foo',
  dealerDetailsV1: 'foo',
  manufacturerMetadataV1: 'foo',
  instanceManagementV2: 'foo',
  assetMetadataV1: 'foo',
  userOnboardingApps: {
    IN: ['PCC', 'FM3'],
  },
  testAdminUserOnboardingApps: {
    IN: ['PCC', 'FM3'],
  },
  applicationUserManagementFM3Apps: {
    IN: ['FM3'],
  },
  userPreferencesV2: 'foo',
  announcementStartDate: '2023-04-21T00:00:00',
  announcementEndDate: '2023-04-21T00:00:00',
  announcementContent: 'test-123',
  blocksComponents: true,
  cuobTestFlag: false,
  ownershipRequestsFlag: true,
  assetRecommendationsFlag: true,
  adminsFlag: true,
  serialNumberValidationFlag: true,
  accessRequestsDateFilterFlag: true,
  resentInvitationDetailsFlag: false,
  notificationsFlag: true,
  autoApproveFlag: true,
  assetsDCDCNFlag: false,
  restrictedAccountsFlag: true,
  customersConsolidateFlag: false,
  AMTChangesOwnershipFlag: false,
  catFiAssociationFlag: true,
};

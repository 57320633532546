import React, { CSSProperties, useState } from 'react';
import { TextField, Box, IconButton, InputAdornment, useTheme } from '@mui/material';
import { FormikProps } from 'formik';
import useStyles from './styles';
import SearchIcon from '@mui/icons-material/Search';
import TextWithToolTip from '../text-with-tooltip';
import { getValueFromObject } from '../../utils/util';
import clsx from 'clsx';
import CAT_COLOR from '../../globals/color-properties';

type FormInputProperties = {
  fullWidth?: boolean;
  hideUnderline?: boolean;
  label: string;
  inputAccessorKey: string;
  requiredField?: boolean;
  disabled?: boolean;
  autoComplete?: boolean;
  multiline?: boolean;
  customClass?: string;
  showEndAdornment?: boolean;
  style?: CSSProperties;
  customErrorMessage?: boolean;
  fixedPosition?: boolean;
  maxCharLength?: number;
  shrinkLabel?: boolean;
  nestedInputKey?: boolean;
  submitOnEnter?: boolean;
  testId?: string;
} & FormikProps<any>;

const FormInput = (props: FormInputProperties) => {
  const { classes } = useStyles();
  const {
    label,
    style,
    requiredField = false,
    inputAccessorKey,
    fullWidth = true,
    values,
    handleBlur,
    handleChange,
    touched,
    multiline = false,
    errors,
    disabled = false,
    autoComplete = true,
    customClass,
    showEndAdornment,
    handleSubmit,
    maxCharLength,
    hideUnderline,
    customErrorMessage,
    fixedPosition = true,
    shrinkLabel,
    nestedInputKey,
    submitOnEnter = true,
    testId,
  } = props;

  const [charLength, setCharLength] = useState<number>(0);

  const theme = useTheme();

  const handleChangeWithCount = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    handleChange(event);

    maxCharLength && setCharLength(event.target.value.length);
  };

  const getInputProps = () => {
    let inputProps = {};

    inputProps = showEndAdornment
      ? {
          ...inputProps,

          disableUnderline: hideUnderline,
          endAdornment: (
            <InputAdornment position="end">
              {values[inputAccessorKey] ? (
                <IconButton
                  data-testid="search-input-btn"
                  onClick={handleSubmit as any}
                  size="large"
                >
                  <SearchIcon className={classes.searchIcon} />
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }
      : inputProps;

    inputProps = multiline
      ? {
          ...inputProps,

          inputProps: {
            style: {
              resize: 'both',
              minHeight: '26px',
            },
          },
        }
      : inputProps;

    return inputProps;
  };

  return (
    <Box
      mb={
        customErrorMessage ||
        (nestedInputKey &&
          getValueFromObject(touched, inputAccessorKey) &&
          Boolean(getValueFromObject(errors, inputAccessorKey)))
          ? 0
          : 2
      }
      mt={
        nestedInputKey &&
        getValueFromObject(touched, inputAccessorKey) &&
        Boolean(getValueFromObject(errors, inputAccessorKey))
          ? 0.75
          : 0
      }
      alignContent="center"
    >
      <TextField
        style={style}
        variant="standard"
        id={label.replace(/ /g, '-').toLocaleLowerCase()}
        fullWidth={fullWidth}
        data-testid={testId ? testId : label.replace(/ /g, '-').toLocaleLowerCase()}
        className={customClass ? customClass : classes.textField}
        multiline={multiline}
        label={label}
        value={
          nestedInputKey ? getValueFromObject(values, inputAccessorKey) : values[inputAccessorKey]
        }
        onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
          !submitOnEnter && event.key === 'Enter' && event.preventDefault();
        }}
        name={inputAccessorKey}
        onChange={(event) => handleChangeWithCount(event)}
        onBlur={handleBlur}
        disabled={disabled}
        autoComplete={autoComplete ? 'on' : 'no'}
        error={
          nestedInputKey
            ? getValueFromObject(touched, inputAccessorKey) &&
              Boolean(getValueFromObject(errors, inputAccessorKey))
            : touched[inputAccessorKey] && Boolean(errors[inputAccessorKey])
        }
        helperText={
          customErrorMessage
            ? ''
            : nestedInputKey
              ? getValueFromObject(touched, inputAccessorKey) &&
                getValueFromObject(errors, inputAccessorKey)
              : touched[inputAccessorKey] && errors[inputAccessorKey]
        }
        required={requiredField}
        InputLabelProps={{
          shrink: shrinkLabel,
        }}
        InputProps={getInputProps()}
      />
      {customErrorMessage && touched[inputAccessorKey] && Boolean(errors[inputAccessorKey]) && (
        <Box
          marginTop={theme.spacing(1)}
          color={CAT_COLOR.PIGMENT_RED}
          position={fixedPosition ? 'fixed' : 'unset'}
        >
          <TextWithToolTip
            fontSize="14px"
            marginLeft="4px"
            width="100%"
            longText={errors[inputAccessorKey] as string}
          />
        </Box>
      )}

      {maxCharLength && (
        <Box
          data-testid="char-length"
          className={clsx(classes.counter, customClass ? customClass : classes.textField)}
        >
          {charLength}/{maxCharLength}
        </Box>
      )}
    </Box>
  );
};

export default FormInput;

/* eslint-disable unicorn/prefer-module */
import { MetaData, CodeVerificationStrategy } from '@kolorado/oauth';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { AuthInfo } from '../../model/types';
import { InternalAxiosRequestConfig } from 'axios';
import { Store } from 'redux';
const crypto = require('crypto');
const base64UrlEncoder = require('base64url');
dayjs.extend(isBetween);

export interface AuthConfig {
  codeVerification?: CodeVerificationStrategy;
  uri: string;
  tokenURI: string;
  clientID: string;
  redirectURI: string;
  oauthAdapter: string;
  responseType: string;
  antiForgeryTtl?: number;
  proofCodeTtl?: number;
  leniency?: number;
}

const currentTime = new Date('October 22, 2020 09:16:07');
export const currentExpectedNounce = Math.round(currentTime.getTime() / 1000);

const nearestMultiple = (index: number, index_: number) => {
  return Math.ceil(index / index_) * index_;
};
export const buildExpectedAntiForgeryToken = (
  authConfig: AuthConfig,
  clientToken: string = '',
  providedNounce?: number,
) => {
  const nounce = providedNounce || Math.round(currentTime.getTime() / 1000);

  const { uri, tokenURI, redirectURI, oauthAdapter, responseType, antiForgeryTtl, proofCodeTtl } =
    authConfig;
  const time = nearestMultiple(nounce, antiForgeryTtl || 0);
  const subject = {
    uri,
    tokenURI,
    redirectURI,
    oauthAdapter,
    responseType,
    antiForgeryTtl,
    proofCodeTtl,
    moment: time,
  };
  const signature = JSON.stringify(subject);
  const hash = crypto.createHash('sha256');
  hash.update(signature);
  const encodedSignature = base64UrlEncoder.encode(hash.digest());
  const text = `${encodedSignature}:${clientToken}${
    // eslint-disable-next-line unicorn/consistent-destructuring
    authConfig.codeVerification === 'Synchronized'
      ? `:${buildExpectedSynchronizationKey(authConfig, nounce)}`
      : ''
  }`;
  const token = base64UrlEncoder.encode(text);
  return token;
};

export const buildExpectedCodeVerifier = (
  authConfig: AuthConfig,
  nounce: number = currentExpectedNounce,
) => {
  const { uri, tokenURI, redirectURI, oauthAdapter, responseType, antiForgeryTtl, proofCodeTtl } =
    authConfig;
  const step = nearestMultiple(nounce, proofCodeTtl || 0);
  const subject = {
    uri,
    tokenURI,
    redirectURI,
    oauthAdapter,
    responseType,
    antiForgeryTtl,
    proofCodeTtl,
    browserInfo: {
      name: navigator.appName,
      userAgent: navigator.userAgent,
      vendor: navigator.vendor,
      version: navigator.appVersion,
      codeName: navigator.appCodeName,
    },
    step,
  };
  const signature = JSON.stringify(subject);
  const hash = crypto.createHash('sha256');
  hash.update(signature);
  const codeVerifier = base64UrlEncoder.encode(hash.digest());
  return codeVerifier;
};

export const buildExpectedSynchronizationKey = (
  authConfig: AuthConfig,
  nounce: number = currentExpectedNounce,
) => {
  const { uri, tokenURI, redirectURI, oauthAdapter, responseType, antiForgeryTtl, proofCodeTtl } =
    authConfig;
  const subject = {
    uri,
    tokenURI,
    redirectURI,
    oauthAdapter,
    responseType,
    antiForgeryTtl,
    proofCodeTtl,
    browserInfo: {
      name: navigator.appName,
      userAgent: navigator.userAgent,
      vendor: navigator.vendor,
      version: navigator.appVersion,
      codeName: navigator.appCodeName,
    },
  };
  const signature: string = JSON.stringify(subject);
  const hash = crypto.createHash('sha256');
  hash.update(signature);
  const key = hash.digest();
  const resizedIV = Buffer.allocUnsafe(16);
  const iv = crypto.createHash('sha256').update(signature).digest();

  iv.copy(resizedIV);

  const cipher = crypto.createCipheriv('AES-256-GCM', key, resizedIV);
  cipher.update(`${nounce}`, 'binary', 'hex');
  const synhronizationKey = cipher.final('hex');
  return synhronizationKey;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      // eslint-disable-next-line unicorn/prefer-spread
      .split('')
      .map((c) => {
        // eslint-disable-next-line unicorn/prefer-code-point
        return `%${`00' ${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

export const isExpiredFunction = (metadata: MetaData) => {
  const currentUnixTime = Math.round(Date.now() / 1000);
  const isExpired = metadata.exp < currentUnixTime;
  return isExpired;
};

export const buildExpectedCodeChallenge = (authConfig: AuthConfig) => {
  const codeVerifier = buildExpectedCodeVerifier(authConfig);
  const hash = crypto.createHash('sha256');
  hash.update(codeVerifier);
  const codeChallenge = base64UrlEncoder.encode(hash.digest());
  return codeChallenge;
};

export const updateAndAttachTokenToCall = async (
  config: InternalAxiosRequestConfig | any,
  setSessionExpiredCallback: Function,
  store: Store,
  updateToken: boolean,
  refreshAuthTokenCallback: Function,
) => {
  if (store.getState().login.loginTime) {
    const refreshUserToken = dayjs(Date.now()).isBetween(
      dayjs(store.getState().login.loginTime).add(15, 'm'),
      dayjs(store.getState().login.loginTime).add(119, 'm'),
    );
    const isTokenExpired = dayjs(Date.now()).isAfter(
      // after two hours the refresh token cannot be used, so we must trigger a hard refresh and let fedlogin determine if they need to
      // sign in again
      dayjs(store.getState().login.loginTime).add(119, 'm'),
    );
    if (isTokenExpired && updateToken && !store.getState().login.isRefreshTokenLoading) {
      setSessionExpiredCallback(true);
    } else if (refreshUserToken && updateToken && !store.getState().login.isRefreshTokenLoading) {
      const authInfo = await refreshAuthTokenCallback(
        store.getState().login.authInfo,
        store.dispatch,
        store.getState().login.environmentVariables,
      );

      if ((authInfo as AuthInfo)?.token) {
        config.headers.Authorization = `Bearer ${(authInfo as AuthInfo)?.token}`;
      }
    } else {
      if (store.getState().login.authInfo.token && !store.getState().login.isRefreshTokenLoading) {
        config.headers.Authorization = `Bearer ${store.getState().login.authInfo.token}`;
      }
    }
  }
  return config;
};

export type stepObject = {
  stepLabel: string;
  stepUrl: string;
};

/* eslint-disable unicorn/no-array-for-each */
import { Box, Divider, Grid, IconButton, Typography, useTheme } from '@mui/material';
import React, { ReactElement, useMemo, useState } from 'react';
import useStyles from './styles';
import StepperButton from '../../../../components/stepper-button';
import SubHeader from '../../../../components/sub-header';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import AppStepper from '../../../../components/app-stepper';
import shortid from 'shortid';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import CustomerAddressDisplay from '../../../../components/customer-address';
import { Accordion, AccordionSummary, AccordionDetails } from '../../../../components/accordion';
import ChipComponent from '../../../../components/chip';
import { CreateInviteErrorResponse } from '../../../../entities/user-onboarding-v1/createInviteErrorResponse';
import { InviteResponse } from '../../../../entities/user-onboarding-v1/inviteResponse';
import { INVITATION_APPLICATIONS } from '../../../../services/invitations';
import CopyButton from '../../../../components/copy-button';
import InlineToast from '../../../../components/inline-toast';
import MultilineTextWithToolTip from '../../../../components/multiline-text-with-tooltip';
import CAT_COLOR from '../../../../globals/color-properties';
import { formatApplicationErrorMessageDetailsOnly } from '../../../../utils/user-onboading.util';
import { CustomerInfoWrapper } from '../../../my-customers/wrappers';
import TEXT from '../../../../globals/translation-map';
import { useTranslation } from 'react-i18next';
import { stepObject } from '../../../../globals/authentication/utils';
import { ErrorDetails } from '../../../../utils/util';

type InviteUsersReviewProperties = {
  steps: Array<stepObject>;
};

const InviteUsersReview = (props: InviteUsersReviewProperties) => {
  const { steps } = props;
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const { navigationPath, environmentVariables } = useSelector((state: AppState) => state.login);
  const {
    createLinks,
    createInvitations: { invitationsResponse, selectedCustomer: selectedInviteUsers },
  } = useSelector((state: AppState) => state.invitation);
  const { applications } = useSelector((state: AppState) => state.shared.metadata);

  // temporary: get appid based on application name
  const getApplicationIdByMessage = (message: string) => {
    return applications.find((item: any) => message.includes(item.applicationName))?.applicationId;
  };

  const [appSuccessError, setAppSuccessError] = useState<{
    [key: string]: { success: Array<any>; error: Array<any> };
  }>({});

  const showAppStepper = location.pathname === '/invitations/review';

  useMemo(() => {
    const newInvitesSuccessVisionLink: Array<InviteResponse> = [];
    const newInvitesSuccessPartsCat: Array<InviteResponse> = [];
    const newInvitesErrorVisionLink: Array<CreateInviteErrorResponse> = [];
    const newInvitesErrorPartsCat: Array<CreateInviteErrorResponse> = [];

    const successErrorMap: any = {};

    if (invitationsResponse && invitationsResponse.length > 0) {
      /* eslint-disable unicorn/no-array-for-each */
      invitationsResponse?.forEach((invite: any) => {
        if (invite.error) {
          const errorMap: any = [];
          /* eslint-disable unicorn/no-array-for-each */
          invite.error.details.forEach((application: any, key: number) => {
            let applicationId = getApplicationIdByMessage(application.message);

            // temporary, if no application name then it applies to all requested apps
            // get from request payload one by one
            if (!applicationId) {
              applicationId = invite?.applications?.[key]?.applicationId;
            }
            if (!successErrorMap[applicationId as string]) {
              successErrorMap[applicationId as string] = {
                success: [],
                error: [],
              };
            }

            // only allow first error at a time by appid if multiple errors
            if (!errorMap.includes(applicationId)) {
              errorMap.push(getApplicationIdByMessage(application.message));
              successErrorMap[applicationId as string].error.push(invite);

              applicationId === INVITATION_APPLICATIONS.VISION_LINK
                ? newInvitesErrorVisionLink.push(invite)
                : newInvitesErrorPartsCat.push(invite);
            }
          });
        } else {
          invite.applications.forEach((application: any) => {
            if (!successErrorMap[application?.applicationId as string]) {
              successErrorMap[application?.applicationId as string] = {
                success: [],
                error: [],
              };
            }
            successErrorMap[application?.applicationId as string].success.push(invite);

            application.applicationId === INVITATION_APPLICATIONS.VISION_LINK
              ? newInvitesSuccessVisionLink.push(invite)
              : newInvitesSuccessPartsCat.push(invite);
          });
        }
      });
    }

    setAppSuccessError(successErrorMap);
  }, [invitationsResponse]);

  const onOkClick = () => {
    navigate(`/${navigationPath}`);
  };

  const getStepContent = () => {
    return (
      <Box
        mt={theme.spacing(5)}
        mb={theme.spacing(9)}
        alignItems="center"
        data-testid="parent-customer-info-container-invite"
        textAlign="start"
        className={classes.widthContainer}
      >
        <Typography variant="h4">{t(TEXT.MY_CUSTOMERS.CUSTOMER_PROFILE)}</Typography>
        <Grid container pl={2}>
          <Grid item xs={8}>
            <CustomerInfoWrapper
              selectedCompany={selectedInviteUsers}
              customerNameDataTestId="invite-selected-customer-name"
              customerIdDataTestId="invite-selected-customer-id"
            />
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.customerAddress}>
              <Typography data-testid="invite-customer-address" className={classes.companyHeader}>
                {t(TEXT.COMMON.ADDRESS)}
              </Typography>
              <Box className={`${classes.companyInfo}`}>
                <CustomerAddressDisplay
                  showFullAddress={true}
                  width="200px"
                  fontWeight={700}
                  address1={
                    selectedInviteUsers?.customerAddresses?.[0]?.address1 ||
                    selectedInviteUsers?.customerAddresses?.[0]?.address2 ||
                    ''
                  }
                  cityName={selectedInviteUsers?.customerAddresses?.[0]?.cityName}
                  stateOrProvinceCode={
                    selectedInviteUsers?.customerAddresses?.[0]?.stateOrProvinceCode
                  }
                  countryCode={selectedInviteUsers?.customerAddresses?.[0]?.countryCode}
                  postalCode={selectedInviteUsers?.customerAddresses?.[0]?.postalCode}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {invitationsResponse?.length !== undefined && createLinks ? (
          <>
            <Typography
              data-testid="create-invite-links-header"
              variant="h4"
              className={`${classes.widthContainer} ${classes.sentInvitesTitle}`}
            >
              Invitation Links
            </Typography>
            {invitationsResponse.map(
              (responseItem: CreateInviteErrorResponse | any, index: number) => {
                return (
                  <Box className={classes.inviteLinkBox} key={index}>
                    <Box>
                      <Box className={classes.inviteLinkTitle}>{responseItem.email}</Box>
                      {responseItem?.error && (
                        <Box className={classes.inviteLinkInfo}>
                          <InlineToast
                            variant="error"
                            header={t(TEXT.INVITATIONS.REVIEW.FAILURE)}
                            width="800px"
                            text={
                              responseItem?.error?.details?.[0]?.message
                                ? responseItem.error.details[0].message
                                : (responseItem?.error as any)?.description
                            }
                          ></InlineToast>
                        </Box>
                      )}
                    </Box>
                    {responseItem?.invitationLink && (
                      <CopyButton
                        text={t(TEXT.INVITATIONS.REVIEW.COPY_LINK)}
                        copy={responseItem.invitationLink}
                      />
                    )}
                  </Box>
                );
              },
            )}
          </>
        ) : (
          <>
            <Typography
              variant="h4"
              className={`${classes.widthContainer} ${classes.sentInvitesTitle}`}
            >
              {t(TEXT.INVITATIONS.REVIEW.SENT_INVITES)}
            </Typography>
            {!invitationsResponse || invitationsResponse?.length === 0 ? (
              <Box data-testid="invites-not-sent" className={classes.noInvitesMessage}>
                {t(TEXT.INVITATIONS.REVIEW.NO_INVITES_SENT)}
              </Box>
            ) : (
              <Box
                className={`${classes.widthContainer} ${classes.accordionContainer}`}
                data-testid="invite-accordion-container"
              >
                {Object.keys(appSuccessError).map((applicationId: string, objIndex) => (
                  <React.Fragment key={objIndex}>
                    <Accordion defaultExpanded>
                      <AccordionSummary>
                        <Typography className={classes.accordionTitle}>
                          {applications &&
                            applications.length > 0 &&
                            applications?.find(
                              (appObject) => appObject.applicationId === applicationId,
                            )?.applicationName}
                        </Typography>
                        <Box className={classes.chipContainer}>
                          {appSuccessError[applicationId]?.success?.length > 0 && (
                            <ChipComponent
                              id={'successful-chip'}
                              label={t(TEXT.COMMON.SUCCESS_COUNT, {
                                count: appSuccessError[applicationId]?.success?.length,
                              })}
                              backgroundColor={CAT_COLOR.LIGHT_SLATE_GREY}
                              foreColor={CAT_COLOR.WHITE}
                              fontSize={'10px'}
                              fontWeight="bold"
                              minWidth={'110px'}
                              blocks={environmentVariables.blocksComponents}
                              variant="green"
                            />
                          )}
                          {appSuccessError[applicationId]?.error?.length > 0 && (
                            <ChipComponent
                              id={'failed-chip'}
                              label={t(TEXT.COMMON.FAILED_COUNT, {
                                count: appSuccessError[applicationId]?.error?.length,
                              })}
                              backgroundColor={theme.palette.error.main}
                              foreColor={CAT_COLOR.WHITE}
                              fontSize={'10px'}
                              fontWeight="bold"
                              minWidth={'110px'}
                              blocks={environmentVariables.blocksComponents}
                              variant="red"
                            />
                          )}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails variant={'grey'}>
                        <Box className={classes.accordionDetailsContainer}>
                          <Box
                            className={classes.accordionList}
                            data-testid="accordion-successful-list-container"
                          >
                            <Typography
                              className={classes.accordionListTitle}
                              data-testid="accordion-body-successful-header"
                            >
                              {t(TEXT.INVITATIONS.REVIEW.SUCCESS_APPS_INVITE, {
                                count: appSuccessError[applicationId]?.success?.length,
                              })}
                            </Typography>
                            {appSuccessError[applicationId]?.success?.length === 0 && (
                              <Typography>({t(TEXT.COMMON.NONE)})</Typography>
                            )}
                            {appSuccessError[applicationId]?.success?.map(
                              (invite, index: number) => {
                                return (
                                  <React.Fragment key={index}>
                                    <Box className={classes.successfulInvitesCustomerInfo}>
                                      <MultilineTextWithToolTip
                                        data-testid="successful-invited-customer-id"
                                        className={classes.customerEmail}
                                        numberOfLines={1}
                                        text={invite.email}
                                      ></MultilineTextWithToolTip>
                                    </Box>
                                  </React.Fragment>
                                );
                              },
                            )}
                          </Box>
                          <Box
                            className={classes.accordionList}
                            data-testid="accordion-failed-list-container"
                          >
                            <Typography
                              className={classes.accordionListTitle}
                              data-testid="accordion-body-failed-header"
                            >
                              {t(TEXT.INVITATIONS.REVIEW.FAIL_APP_INVITE, {
                                count: appSuccessError[applicationId]?.error?.length,
                              })}
                            </Typography>
                            {appSuccessError[applicationId]?.error?.length === 0 && (
                              <Typography>({t(TEXT.COMMON.NONE)})</Typography>
                            )}

                            {appSuccessError[applicationId]?.error?.map(
                              (invite: CreateInviteErrorResponse, index: number) => {
                                const applicationName =
                                  (applications &&
                                    applications.length > 0 &&
                                    applications?.find(
                                      (appObject) => appObject.applicationId === applicationId,
                                    )?.applicationName) ||
                                  '';

                                return (
                                  <React.Fragment key={index}>
                                    <Box className={classes.failedInvitesCustomerInfo}>
                                      <Box className={classes.errorDescription}>
                                        <MultilineTextWithToolTip
                                          data-testid="failed-invited-customer-id"
                                          className={classes.customerEmail}
                                          numberOfLines={1}
                                          text={invite.email}
                                        ></MultilineTextWithToolTip>
                                        <Box className={classes.inlineToastBox}>
                                          <InlineToast
                                            variant="error"
                                            header={t(TEXT.INVITATIONS.REVIEW.FAILURE)}
                                            text={
                                              formatApplicationErrorMessageDetailsOnly(
                                                invite.error as unknown as ErrorDetails,
                                                applicationName,
                                              ) || ''
                                            }
                                          ></InlineToast>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                );
                              },
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    );
  };
  const actionButtons = [
    <StepperButton
      buttonText={t(TEXT.COMMON.OK)}
      id="ok-review-results"
      color="primary"
      type="button"
      onClick={() => onOkClick()}
    />,
  ];
  return (
    <Box data-testid="review-results-invite-users-page">
      <SubHeader
        title={showAppStepper ? t(TEXT.INVITATIONS.TITLE.INVITE_USER) : ''}
        titleText={showAppStepper ? t(TEXT.COMMON.TITLE.REVIEW_RESULTS) : ''}
        data-testid="review-results-invite-users-header"
        actionComponents={[
          <IconButton
            onClick={() => {
              navigate(`/${navigationPath}`);
            }}
            data-testid="review-results-invite-users-close-icon"
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Divider />
      {steps && (
        <Box mt={theme.spacing(1)}>
          <AppStepper
            steps={steps}
            getStepContent={getStepContent}
            activeStep={3}
            blocks={environmentVariables.blocksComponents}
          />
        </Box>
      )}
      <Box
        bottom={0}
        right={0}
        width="100%"
        height={theme.spacing(10)}
        mr={2}
        pb={2}
        className={classes.footerActions}
        position="fixed"
      >
        <Divider className={classes.divider} />
        <Box display="flex" justifyContent="flex-end" mt={2} mb={1} width="100%">
          {actionButtons.map((component: ReactElement) => (
            <Box key={shortid.generate()} mr={2}>
              {component}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default InviteUsersReview;

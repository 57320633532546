import { AxiosResponse } from 'axios';
import { AppState, AppThunkDispatch } from '..';
import { ApplicationsResponse } from '../../entities/user-onboarding-v1/applicationsResponse';
import { PartyType } from '../../entities/user-onboarding-v1/partyType';
import { RolesResponse } from '../../entities/user-onboarding-v1/rolesResponse';
import InvitationsService, { INVITATION_APPLICATIONS } from '../../services/invitations';
import { formatDetailedErrorMessage, showNotification } from '../../utils/util';
import { metadataActionNames } from './types';
import ManufacturerMetadata from '../../services/manufacturer-metadata';
import i18n from 'i18next';
import TEXT from '../../globals/translation-map';

const MAX_LOOP_CALL = 6;

export const getApplicationsCache = () => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables, permissionsUpdateFlag },
      shared: {
        metadata: { isLoadingDC, applications },
      },
    } = getState();

    if (!isLoadingDC && ((applications && applications.length === 0) || permissionsUpdateFlag)) {
      const invitations = new InvitationsService(authInfo, environmentVariables.userOnboardingV1);
      try {
        dispatch({
          type: metadataActionNames.SET_APPLICATIONS_LOADING_DC,
          payload: true,
        });

        const metadataResponse = (await invitations.getMetadata({
          body: {
            logicalExpression: '$0',
            filters: [
              {
                propertyName: 'applicationId',
                type: 'stringEquals',
                values: ['FM3', 'PCC'],
              },
            ],
            responseAttributes: [
              'applicationId',
              'applicationName',
              'accessType',
              'accessIdentifierSource',
              'parties',
              'parties_partyNumber',
              'parties_partyName',
              'parties_partyType',
              'parties_administratorsCount',
              'parties_isRestrictedParty',
            ],
          },
        })) as AxiosResponse<ApplicationsResponse>;

        dispatch({
          type: metadataActionNames.SET_APPLICATIONS,
          payload: metadataResponse?.data?.applications || [],
        });
        dispatch({
          type: metadataActionNames.SET_APPLICATIONS_LOADING_DC,
          payload: false,
        });
      } catch (error: any) {
        showNotification(
          dispatch,
          'error',
          formatDetailedErrorMessage(error) ||
            i18n.t(TEXT.COMMON.METADATA.ERRORS.RETRIEVE_APPLICATION_CACHE),
        );
        dispatch({
          type: metadataActionNames.SET_APPLICATIONS_LOADING_DC,
          payload: false,
        });
      }
    }
  };
};

export const getAppRoles = (account: string, ccid?: string) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
    } = getState();

    dispatch({
      type: metadataActionNames.SET_APPLICATION_ROLES,
      payload: [],
    });
    const invitations = new InvitationsService(authInfo, environmentVariables.userOnboardingV1);
    try {
      dispatch({
        type: metadataActionNames.SET_APPLICATIONS_LOADING_FAILED,
        payload: false,
      });
      dispatch({
        type: metadataActionNames.SET_APPLICATIONS_LOADING_ROLE,
        payload: true,
      });
      const response = (await invitations.getApplicationRoles(
        INVITATION_APPLICATIONS.VISION_LINK,
        account,
        ccid,
      )) as AxiosResponse<RolesResponse>;
      if (response?.data?.roles) {
        dispatch({
          type: metadataActionNames.SET_APPLICATION_ROLES,
          payload: response.data.roles.filter((role) => role?.partyType === PartyType.CUST),
        });
        dispatch({
          type: metadataActionNames.SET_APPLICATIONS_LOADING_ROLE,
          payload: false,
        });
      }
      return [];
    } catch (error: any) {
      dispatch({
        type: metadataActionNames.SET_APPLICATIONS_LOADING_ROLE,
        payload: false,
      });
      dispatch({
        type: metadataActionNames.SET_APPLICATIONS_LOADING_FAILED,
        payload: true,
      });
      showNotification(
        dispatch,
        'error',
        formatDetailedErrorMessage(error) || i18n.t(TEXT.COMMON.METADATA.ERRORS.RETRIEVE_ROLES),
      );
      return [];
    }
  };
};

export const getManufacturers = (nextCursor?: string, loopCallCount: number = 0) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    if (loopCallCount === MAX_LOOP_CALL) return null;

    const {
      login: { authInfo, environmentVariables },
      shared: {
        metadata: { manufacturers },
      },
    } = getState();
    const manufacturerMetadataService = new ManufacturerMetadata(
      authInfo,
      environmentVariables.manufacturerMetadataV1,
    );

    try {
      const response = await manufacturerMetadataService.searchManufacturersCrossReference(
        {
          cursor: nextCursor,
          limit: '2500',
          orderBy: 'ASC',
          returnActiveMakeCodes: true,
        },
        {
          logicalExpression: '$0',
          filters: [
            {
              propertyName: 'sourceIdentifier',
              type: 'stringEquals',
              values: ['ED'],
            },
          ],
        },
      );
      if (response?.data?.manufacturerCrossReferences) {
        dispatch({
          type: metadataActionNames.SET_APPLICATIONS_MANUFACTURERS,
          payload: [...manufacturers, ...response.data.manufacturerCrossReferences],
        });
      }
      if (response.data.responseMetadata?.nextCursor) {
        dispatch(getManufacturers(response.data.responseMetadata?.nextCursor, ++loopCallCount));
      }
      return [];
    } catch (error: any) {
      showNotification(
        dispatch,
        'error',
        error?.message || 'Cannot Retrieve Manufacturer Metadata',
      );
      return [];
    }
  };
};
